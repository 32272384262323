import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiConfig, envConfigSetting } from "../utils/config";
import { LoadingStatus, Languages } from "../utils/constant";
import { LiqaOptions } from '../utils/liqaoptions'

import Loader from "./Common/Loader";
import Error from "./Error";
const config = envConfigSetting.config;

async function uploadImageToS3Bucket(image, userId) {
  try {
    const { folder } = config.s3Bucket;

    // Prepare S3 parameters
    const imageName = `${userId}-${Date.now()}-image`;
    const objectKey = `${folder}/${imageName}.jpg`;

    const uploadImgToS3Image = await axios.get(
      `${config.apiUrl}/face-age/v1/get-s3-image-upload-url?fileName=${objectKey}`)

    console.log("uploadImgToS3Image", uploadImgToS3Image.data)

    if (uploadImgToS3Image?.data?.statusCode !== 200) {
      return null;
    }

    const { signedUrl } = uploadImgToS3Image?.data?.data

    console.log(image.type, "img type")
    const imageUploadResponse = await axios.put(signedUrl, image, {
      headers: {
        'Content-Type': image.type, // Set the correct file type
      },
    });

    if (imageUploadResponse.status !== 200) {
      return null;
    }

    const imageUrl = signedUrl.split('?')[0];

    return { imageUrl, imageName, objectKey };
  } catch (error) {
    console.error("Error uploading:", error);
    console.log("Error in uploading image", error)
    return null;
  }
}

export default function LiqaWebView() {
  const [loadingStatus, setLoadingStatus] = useState({
    status: LoadingStatus.TOLOAD,
    success: false,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [language, setLanguage] = useState(Languages.ENGLISH)

  const ref = useRef();

  const params = useParams();
  const user_id = params.userId;

  const handleImageCapture = useCallback(
    async (event) => {
      const capture = event.detail;

      if (!capture || !user_id) return;

      const errorLink = `/error/${user_id}?code=400&message=Failed to upload image`;

      try {
        setShowLoader(true);
        const image = await capture.blob();

        if (!image) return;

        const resUploadImage = await uploadImageToS3Bucket(image, user_id);

        if (!resUploadImage) {
          window.location.href = errorLink;
          return;
        }

        const { imageUrl, imageName, objectKey } = resUploadImage;


        const uploadImgToS3Image = await axios.post(
          `${config.apiUrl}/face-age/v1/upload-s3-image`,
          {
            user_id,
            imageUrl,
            objectKey,
            name: imageName,
            light_id: 1,
            side_id: 1,
          },
          apiConfig
        );

        if (uploadImgToS3Image.data.statusCode !== 200) {
          window.location.href = errorLink;
          return;
        }

        const data = JSON.stringify({ imageUrl, imageName });
        window.location.href = `/success/${user_id}?code=200&data=${data}`;
      } catch (error) {
        console.log(error);
        window.location.href = `/error/${user_id}?code=400&data=${error}`;
      }
    },
    [user_id]
  );

  const onLoad = useCallback(async () => {
    if (!user_id) {
      setLoadingStatus({
        status: LoadingStatus.LOADED,
        success: false,
      });
      return;
    }

    let success = false;
    try {
      const userAPICall = await axios.post(
        `${config.apiUrl}/face-age/v1/homehandler`,
        {
          user_id: btoa(user_id),
        },
        apiConfig
      );

      if (userAPICall?.data?.statusCode === 200) {
        success = true;
        const userLanguage = userAPICall?.data?.data?.user_data?.language || Languages.ENGLISH
        setLanguage(userLanguage)
        console.log("userLanguage", userLanguage)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoadingStatus({
          status: LoadingStatus.LOADED,
          success,
        });
      }, 100);
    }
  }, [user_id]);

  useEffect(() => {
    let ref1 = ref.current;
    if (ref1) {
      ref1.addEventListener("capture", handleImageCapture);
      return () => ref1.removeEventListener("capture", handleImageCapture);
    }
  });

  useEffect(() => {
    let ref1 = ref?.current;
    const timerId = setTimeout(() => {
      setLoadingStatus({
        status: LoadingStatus.LOADING,
        success: false,
      });
      onLoad(ref1);
    }, 100);

    return () => {
      clearTimeout(timerId); // Clear the timeout on component unmount
    };
  }, [handleImageCapture, onLoad]); // Add an empty dependency array to ensure it runs only once after the initial render

  return useMemo(() => {
    const { status, success } = loadingStatus;

    if (status === LoadingStatus.TOLOAD) return <Loader title="Initiating Liqa" />;

    if (status === LoadingStatus.LOADING) return <Loader />;

    if (status === LoadingStatus.LOADED && !success)
      return <Error title="User not found !" />;

    return (
      <React.Fragment>
        <div className="liqa-container">
          <hautai-liqa
            ref={ref}
            license="eyJpZCI6IkhFU04tOTAxNS0wIn0="
            preset="face"
            sources="camera"
            styles=":host { height:100vh; display:block }"
            messages={JSON.stringify(LiqaOptions[language])}
          ></hautai-liqa>
          {showLoader && <Loader title="Processing..." />}
        </div>
      </React.Fragment>
    );
  }, [loadingStatus, showLoader, language]);
}
